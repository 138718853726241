<template>
  <index-portfolio />
</template>

<script>
import IndexPortfolio from '@/components/Portfolio/Index';

export default {
  name: "Portfolio",
  components: {
    IndexPortfolio,
  }
}
</script>

<style scoped>

</style>